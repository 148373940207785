// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src178295222/src/gatsby-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nola-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/nola.js" /* webpackChunkName: "component---src-pages-nola-js" */),
  "component---src-pages-registry-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-the-couple-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/the-couple.js" /* webpackChunkName: "component---src-pages-the-couple-js" */),
  "component---src-pages-wedding-details-js": () => import("/codebuild/output/src178295222/src/gatsby-site/src/pages/wedding-details.js" /* webpackChunkName: "component---src-pages-wedding-details-js" */)
}

