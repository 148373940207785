module.exports = [{
      plugin: require('/codebuild/output/src178295222/src/gatsby-site/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/fld.png"},
    },{
      plugin: require('/codebuild/output/src178295222/src/gatsby-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src178295222/src/gatsby-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
